/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { RiPrinterLine, RiDownloadLine, RiArrowLeftLine, RiCheckLine, RiQuestionLine, RiUserSearchLine, RiTeamLine, RiLightbulbLine } from "react-icons/ri"

const InterviewQuestionBankDownloadPage = () => {
  
  // Function to handle printing the template
  const handlePrint = () => {
    if (typeof window !== 'undefined') {
      window.print();
    }
  }
  
  return (
    <Layout>
      <SEO 
        title="Interview Question Bank | Comprehensive Candidate Assessment Tool"
        description="Download our curated interview question bank organized by competency, role and question type. Improve your hiring decisions with structured interview techniques."
        keywords={[
          "interview questions bank",
          "hiring question template",
          "recruitment interview guide",
          "behavioral interview questions",
          "competency based questions",
          "situational interview template",
          "technical interview questions",
          "candidate assessment questions",
          "structured interview guide",
          "job interview preparation"
        ]}
      />
      
      {/* Hero Section */}
      <div sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        py: [4, 5],
        position: 'relative'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            maxWidth: '800px',
            mx: 'auto'
          }}>
            <Link 
              to="/guides/recruitment-hiring" 
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                color: 'primary',
                fontSize: '0.9rem',
                textDecoration: 'none',
                mb: 3,
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              <RiArrowLeftLine sx={{ mr: 1 }} /> Back to Recruitment & Hiring Guide
            </Link>
            
            <h1 sx={{
              fontSize: ['1.8rem', '2.2rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary'
            }}>
              Interview Question Bank
            </h1>
            
            <p sx={{
              fontSize: '1rem',
              lineHeight: 1.5,
              mb: 4,
              color: 'text',
              opacity: 0.9
            }}>
              A comprehensive collection of interview questions organized by competency, role, and question type
              to help you conduct effective, structured interviews and make better hiring decisions.
            </p>
            
            <div sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 3,
              mb: 4
            }}>
              <button 
                onClick={handlePrint}
                type="button"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  border: 'none',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.15)'
                  }
                }}
              >
                <RiPrinterLine /> Print Template
              </button>
              
              <Link 
                to="/contact"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '0.95rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  border: '2px solid',
                  borderColor: 'primary',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 15px rgba(0,0,0,0.1)'
                  }
                }}
              >
                <RiDownloadLine /> Request Full Question Bank
              </Link>
            </div>
          </div>
        </div>
      </div>
      
      {/* Main content */}
      <div className="printable-content" sx={{
        maxWidth: '1200px',
        mx: 'auto',
        px: 3,
        py: [4, 5],
        '@media print': {
          padding: '0',
          fontSize: '12pt'
        }
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          bg: 'white',
          p: [3, 4],
          borderRadius: '8px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          '@media print': {
            boxShadow: 'none',
            padding: '0'
          }
        }}>
          {/* Introduction Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Introduction to Structured Interviewing
            </h2>
            <p sx={{ mb: 3, lineHeight: 1.6 }}>
              Effective interviewing is a critical component of the hiring process. Structured interviews, where 
              each candidate is asked the same set of predefined questions, have been shown to be more reliable, 
              valid, and legally defensible than unstructured conversations.
            </p>
            <p sx={{ lineHeight: 1.6 }}>
              This question bank provides a comprehensive set of interview questions organized by competency 
              and question type to help you assess candidates consistently and fairly, while gathering relevant 
              information to make informed hiring decisions.
            </p>
          </section>

          {/* Question Types Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Types of Interview Questions
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              {[
                {
                  title: "Behavioral Questions",
                  description: "Assess past behavior to predict future performance based on the principle that past behavior is the best predictor of future performance",
                  icon: <RiUserSearchLine />
                },
                {
                  title: "Situational Questions",
                  description: "Present hypothetical scenarios to evaluate how candidates might handle specific work situations",
                  icon: <RiLightbulbLine />
                },
                {
                  title: "Technical Questions",
                  description: "Evaluate specific knowledge, skills, and abilities required for the position",
                  icon: <RiCheckLine />
                },
                {
                  title: "Cultural Fit Questions",
                  description: "Assess alignment with company values, work style, and team dynamics",
                  icon: <RiTeamLine />
                }
              ].map((questionType) => (
                <div 
                  key={questionType.title}
                  sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'muted',
                    bg: '#f8f9fa'
                  }}
                >
                  <div sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 2,
                    color: 'primary' 
                  }}>
                    <span sx={{ fontSize: '1.5rem', mr: 2 }}>{questionType.icon}</span>
                    <h3 sx={{ fontSize: '1.2rem', fontWeight: 600, m: 0 }}>
                      {questionType.title}
                    </h3>
                  </div>
                  <p sx={{ fontSize: '0.95rem', lineHeight: 1.6, m: 0 }}>
                    {questionType.description}
                  </p>
                </div>
              ))}
            </div>
          </section>
          
          {/* Sample Questions Section */}
          <section sx={{ mb: 5 }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Sample Questions by Competency
            </h2>
            
            {/* Leadership & Management Competency */}
            <div sx={{ mb: 4 }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 700,
                mb: 3,
                color: 'text',
                display: 'flex',
                alignItems: 'center'
              }}>
                <RiTeamLine sx={{ mr: 2, color: 'primary' }} /> Leadership & Management
              </h3>
              
              <div sx={{
                border: '1px solid',
                borderColor: 'muted',
                borderRadius: '6px',
                overflow: 'hidden',
                mb: 3
              }}>
                <div sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderColor: 'muted',
                  bg: '#f8f9fa'
                }}>
                  <div sx={{ fontWeight: 600, fontSize: '1rem', mb: 2, color: 'primary' }}>
                    Behavioral Questions
                  </div>
                  <ul sx={{ 
                    pl: 4, 
                    mb: 0, 
                    fontSize: '0.95rem', 
                    lineHeight: 1.6 
                  }}>
                    <li>Describe a time when you had to lead a team through a significant change. How did you manage the process and what was the outcome?</li>
                    <li>Tell me about a time when you had to motivate a team member who was underperforming. What approach did you take?</li>
                    <li>Give an example of a difficult decision you had to make as a leader. How did you reach your decision and communicate it to your team?</li>
                  </ul>
                </div>
                
                <div sx={{ p: 3 }}>
                  <div sx={{ fontWeight: 600, fontSize: '1rem', mb: 2, color: 'primary' }}>
                    Situational Questions
                  </div>
                  <ul sx={{ 
                    pl: 4, 
                    mb: 0, 
                    fontSize: '0.95rem', 
                    lineHeight: 1.6 
                  }}>
                    <li>Imagine your team is struggling to meet a critical deadline. How would you approach the situation?</li>
                    <li>How would you handle a situation where two high-performing team members are in constant conflict?</li>
                    <li>If you were assigned a project with unclear objectives, what steps would you take to clarify expectations and deliver results?</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Problem-Solving & Critical Thinking Competency */}
            <div sx={{ mb: 4 }}>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 700,
                mb: 3,
                color: 'text',
                display: 'flex',
                alignItems: 'center'
              }}>
                <RiLightbulbLine sx={{ mr: 2, color: 'primary' }} /> Problem-Solving & Critical Thinking
              </h3>
              
              <div sx={{
                border: '1px solid',
                borderColor: 'muted',
                borderRadius: '6px',
                overflow: 'hidden',
                mb: 3
              }}>
                <div sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderColor: 'muted',
                  bg: '#f8f9fa'
                }}>
                  <div sx={{ fontWeight: 600, fontSize: '1rem', mb: 2, color: 'primary' }}>
                    Behavioral Questions
                  </div>
                  <ul sx={{ 
                    pl: 4, 
                    mb: 0, 
                    fontSize: '0.95rem', 
                    lineHeight: 1.6 
                  }}>
                    <li>Tell me about a complex problem you faced in your previous role. How did you approach it and what was the outcome?</li>
                    <li>Describe a situation where you had to analyze data to solve a business problem. What methods did you use and what did you learn?</li>
                    <li>Give an example of a time when you identified an opportunity for process improvement. How did you implement the change?</li>
                  </ul>
                </div>
                
                <div sx={{ p: 3 }}>
                  <div sx={{ fontWeight: 600, fontSize: '1rem', mb: 2, color: 'primary' }}>
                    Situational Questions
                  </div>
                  <ul sx={{ 
                    pl: 4, 
                    mb: 0, 
                    fontSize: '0.95rem', 
                    lineHeight: 1.6 
                  }}>
                    <li>If you were given a project with conflicting priorities and limited resources, how would you determine where to focus your efforts?</li>
                    <li>How would you approach a situation where the solution to a problem isn't immediately clear or requires creative thinking?</li>
                    <li>If you discovered a significant error in a project just before its completion deadline, what steps would you take?</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Communication & Collaboration Competency */}
            <div>
              <h3 sx={{ 
                fontSize: '1.2rem', 
                fontWeight: 700,
                mb: 3,
                color: 'text',
                display: 'flex',
                alignItems: 'center'
              }}>
                <RiTeamLine sx={{ mr: 2, color: 'primary' }} /> Communication & Collaboration
              </h3>
              
              <div sx={{
                border: '1px solid',
                borderColor: 'muted',
                borderRadius: '6px',
                overflow: 'hidden'
              }}>
                <div sx={{
                  p: 3,
                  borderBottom: '1px solid',
                  borderColor: 'muted',
                  bg: '#f8f9fa'
                }}>
                  <div sx={{ fontWeight: 600, fontSize: '1rem', mb: 2, color: 'primary' }}>
                    Behavioral Questions
                  </div>
                  <ul sx={{ 
                    pl: 4, 
                    mb: 0, 
                    fontSize: '0.95rem', 
                    lineHeight: 1.6 
                  }}>
                    <li>Describe a situation where you had to communicate complex information to someone with little background knowledge. How did you approach it?</li>
                    <li>Tell me about a time when you had to collaborate with a difficult colleague or stakeholder. How did you manage the relationship?</li>
                    <li>Give an example of a successful cross-functional project you worked on. What was your role and how did you contribute to its success?</li>
                  </ul>
                </div>
                
                <div sx={{ p: 3 }}>
                  <div sx={{ fontWeight: 600, fontSize: '1rem', mb: 2, color: 'primary' }}>
                    Situational Questions
                  </div>
                  <ul sx={{ 
                    pl: 4, 
                    mb: 0, 
                    fontSize: '0.95rem', 
                    lineHeight: 1.6 
                  }}>
                    <li>How would you handle a situation where you need to deliver negative feedback to a colleague or team member?</li>
                    <li>If you were tasked with presenting a controversial proposal to senior leadership, how would you prepare and deliver your message?</li>
                    <li>How would you approach working on a project where team members are in different time zones and primarily communicate virtually?</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          
          {/* Sample Questions Note */}
          <div sx={{ 
            bg: '#f8f9fa',
            p: 3,
            borderRadius: '6px',
            textAlign: 'center',
            border: '1px dashed',
            borderColor: 'muted',
            fontSize: '0.9rem',
            color: 'text',
            opacity: 0.8,
            mb: 5
          }}>
            The complete question bank includes over 200 questions across 12 competency areas, 
            tailored for different industries and organizational levels. 
            Request the full template to access all content.
          </div>
          
          {/* Best Practices Section */}
          <section>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'],
              fontWeight: 700,
              mb: 3,
              color: 'primary',
              borderBottom: '2px solid',
              borderColor: 'muted',
              pb: 2
            }}>
              Interview Best Practices
            </h2>
            
            <ul sx={{ pl: 0, listStyleType: 'none' }}>
              {[
                "Prepare a structured interview guide with questions selected from the bank relevant to the position",
                "Ask the same core questions to all candidates for consistency and fairness",
                "Use the STAR method (Situation, Task, Action, Result) to evaluate answers to behavioral questions",
                "Take detailed notes during interviews to aid in candidate comparison",
                "Use follow-up questions to probe for specific examples and clarify responses",
                "Include multiple interviewers to reduce individual bias in the evaluation process",
                "Allow sufficient time for candidates to ask their own questions at the end of the interview"
              ].map((practice) => (
                <li 
                  key={practice}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 3
                  }}
                >
                  <span sx={{ color: 'primary', mr: 2, pt: '3px' }}>
                    <RiCheckLine />
                  </span>
                  <span sx={{ fontSize: '0.95rem', lineHeight: 1.6 }}>
                    {practice}
                  </span>
                </li>
              ))}
            </ul>
            
            <div sx={{
              bg: '#f8f9fa',
              p: 3,
              mt: 4,
              borderRadius: '6px',
              border: '1px solid',
              borderColor: 'muted',
              fontSize: '0.95rem',
              fontStyle: 'italic'
            }}>
              <strong>Remember:</strong> The most effective interviews balance structure with flexibility. 
              While following a consistent question framework, remain attentive to candidate responses and 
              be prepared to adapt your follow-up questions accordingly to gain deeper insights.
            </div>
          </section>
          
          {/* Print styles */}
          <style jsx>{`
            @media print {
              body {
                font-size: 12pt;
                color: #000;
              }
              h1, h2, h3, h4, h5, h6 {
                break-after: avoid;
              }
              ul, ol, table {
                break-inside: avoid;
              }
              a {
                text-decoration: none;
                color: #000;
              }
              .printable-content {
                padding: 0;
              }
            }
          `}</style>
        </div>
      </div>
    </Layout>
  )
}

export default InterviewQuestionBankDownloadPage 